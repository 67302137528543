<template>
    <div class="home main_margin mt-15 mb-16">
         <v-container class="mx-auto">
             <v-row justify="center" >
                 <v-col cols="10" md="12">
                     <h1 
                     :class="{'h1_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}" 
                     class="h1_for_mobile text-center text-md-left"
                     >
                     IT SERVE
                     </h1>
                 </v-col>
                 <v-col cols="10" md="12" class="mt-2">
                     <h2 
                     class="h2_for_momile text-center text-md-left"
                     :class="{'h2_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                     >
                     Новий погляд на звичні речі
                     </h2>
                     <v-col md="2" class="hidden-sm-and-down">
                            <hr class="hr_yellow mt-2 text-left">
                     </v-col>
                     <v-col cols="4" class="hidden-sm-and-down">
                         <p 
                         :class="{'text_p_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                         class="text_p_for_mobile mt-4"
                         >
                          IT Serve &mdash; компанія-розробник унікальних цифрових технологій
                        </p>
                     </v-col>
                 </v-col>
                 <v-col cols="8" md="12" class="text-center text-md-left mt-11">
                    <v-btn
                     large 
                     outlined 
                     rounded 
                     :to="form"
                     class ="yellow_btn px-10 py-3"> 
                        <span class="yellow_btn_text"> 
                            Залишити заявку 
                        </span>
                    </v-btn>    
                 </v-col>
            </v-row>

            <v-row class="text-center mt-md-n16" justify="center" justify-md="end" >
                <v-col cols="10" md="11" class="mt-6 mt-md-n16">
                    <img class="w_100 mt-md-n10" src="../assets/main_img.png" alt="">
                </v-col>
            </v-row>

            <v-row class="d-md-flex">
                <v-col cols="12" md="6">
                    <v-row justify="center">
                            <v-col cols="12" class="mt-15 mx-auto">
                                <h3 
                                :class="{'h3_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}" 
                                class="h3_for_mobile text-center text-bold title_h2_O_S"
                                >
                                     IT SERVE
                                </h3>
                            </v-col>     
                            <v-col cols="4" md="2">
                                <hr class="hr_yellow mt-2">
                            </v-col>
                    </v-row>          
                    <v-row justify="center" >
                        <v-col cols="11" class="mt-5">
                            <p 
                            v-for="text in texts_info" 
                            :key="text.index"
                            :class="{'text_p_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}" 
                            class="text-center text_p_for_mobile mt-5"
                            >
                                {{ text.text }}
                            </p>
                        </v-col>
                    </v-row > 
                </v-col>

                <v-col cols="12" md="6" class="mt-5 md-mt_cran">
                    <v-row class="text-center mt-md-16" justify="center" justify-md="end" >
                        <v-col cols="9" md="8" class="my-md-16">
                            <img class="w_100" src="../assets/crane.png" alt="">
                        </v-col>
                    </v-row>
                </v-col>
            </v-row> 
              
            <v-row justify="center">
                <v-col cols="12" class="mt-15">
                    <h3 
                    :class="{'h3_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                    class="h3_for_mobile text-3rem text-center text-bold title_h2_O_S">
                        Наша Географія
                    </h3>
                </v-col> 
                <v-col cols="4" md="2">
                        <hr class="hr_yellow mt-2">
                </v-col>
            </v-row> 
            <mainMap class="mt-16" /> 
        </v-container>
        <v-row
        :class="{'bg_grad && bl_border_radius': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
        class="mt-md-16"
        >
            <v-container>
                        <v-row justify="center">
                            <v-col cols="12" class="mt-15">
                                <h3 
                                :class="{'h3_for_desktop_white': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                                class="h3_for_mobile text-3rem text-center text-bold title_h2_O_S">
                                    Флагманські розробки
                                    <br class="hidden-md-and-up">
                                    IT SERVE                         
                                </h3>
                            </v-col>
                            <v-col cols="4" md="2">
                                    <hr class="hr_yellow mt-2">
                            </v-col>     
                        </v-row> 

                <v-row class="hidden-md-and-up">
                    <v-carousel hide-delimiters light height="auto">
                        <v-carousel-item
                        v-for="card in cards" 
                        :key="card.index"
                        >
                            <v-row  justify="center" class="mx-2 mb-7">
                                <v-col cols="12" sm="8" class="card mt-15">
                                    <v-row justify="center">
                                        <v-col cols="10" class="text-center">
                                            <v-row justify="center" class="mx-auto">
                                                <v-col cols="6" class="mt-n13">
                                                    <div class="circile circle_width_picture mx-auto">
                                                        <img class="img_center_positioning " :src="card.img" alt="">
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="center" class="mx-auto  text-center">
                                                <v-col cols="10" class="mt-5">
                                                    <h5 class="title_for_card">
                                                        {{ card.title }}
                                                    </h5>
                                                </v-col>
                                                <v-col cols="12" class="mt-n5">
                                                    <p class="text_p_for_mobile">
                                                        {{ card.text }} 
                                                    </p>
                                                </v-col>  
                                            </v-row>
                                            <v-row class="mb-8">
                                                <v-col class="mt-3" style="height: 45px;">
                                                    <v-btn large outlined rounded :to="projects" class="yellow_btn px-9 py-2">
                                                        <span class="yellow_btn_text"> 
                                                            Читати далі
                                                        </span>
                                                    </v-btn>   
                                                </v-col>
                                            </v-row>
                                        </v-col>  
                                    </v-row>
                                </v-col>  
                            </v-row>
                        </v-carousel-item>
                    </v-carousel>
                </v-row>
                <v-row 
                class="hidden-sm-and-down " 
                justify="space-around"
                >
                    <v-col 
                    v-for="card in cards" 
                    :key="card.index" 
                    cols="3"
                    >
                        <v-row  justify="center" class="mb-7">
                            <v-col cols="12" class="card bg_white mt-15 text-center">
                                            <v-row justify="center" class="mx-auto text-center">
                                                <v-col cols="6" class="mt-n13">
                                                    <div class="circile circle_width_picture mx-auto">
                                                        <img class="img_center_positioning " :src="card.img" alt="">
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="center" class="mx-auto text-center">
                                                <v-col cols="10" class="mt-5">
                                                    <h5 class="title_for_card">
                                                        {{ card.title }}
                                                    </h5>
                                                </v-col>
                                                <v-col cols="12" class="px-2">
                                                    <p class="text_p_for_card">
                                                        {{ card.text }} 
                                                    </p>
                                                </v-col>  
                                            </v-row>
                                            <v-row class="mb-8">
                                                <v-col class="mt-6">
                                                    <v-btn large outlined rounded :to="projects"
                                                     class="yellow_btn btn_for_card px-9 py-2">
                                                        <span class="yellow_btn_text"> 
                                                            Читати далі
                                                        </span>
                                                    </v-btn>   
                                                </v-col>
                                            </v-row>  
                                </v-col>  
                            </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
    </div>
</template>

<script>
import mainMap from './mainMap.vue'
import lamp from '../assets/lamp.png'
import hend from '../assets/hend.png'
import operator from '../assets/operator.png'

export default {
    name: 'Home',
    components: {
        mainMap,
    },
    data: () => ({
        form: "/form",
        projects: "/projects",
       texts_info: [
           {
               text: "Наша команда співпрацює з підприємствами в газовій та енергетичній сфері, з постачальниками теплової енергії та компаніями, що спеціалізуються на водопостачанні та водовідведенні, вивезенні сміття.",
           },
           {
               text: "Наші розробки успішно впроваджені на 15 підприємствах. Ми представлені в багатьох областях України - від Харкова і до Львова.",
           },
           {
               text: "Більш 500 000 осіб вже сьогодні активно використовують наші мобільні додатки.",
           },
           {
               text: "Головною перевагою IT Serve є те, що всі розробки та технології безкоштовно розробляються та передаються в експлуатацію нашим партнерам на безоплатній основі, що виключає необхідність проведення тендерів чи конкурсів.",
           }
       ],
       cards: [
            {
                img: lamp,
                title: 'Smart Region',
                text: "Глобальний проєкт, що не має аналогів в Україні. Єдина електронна система Smart Region дозволить поєднати на єдиній платформі державні та комунальні послуги для населення",
            },
            {
                img: hend,
                title: 'Мобільний додаток для КП',
                text: "Оплата комунальних послуг та передача показань лічильників, а також прямий зв'язок з підприємством та багато іншого",
            },
            {
                img: operator,
                title: 'Кабінет підтримки',
                text: "Кращий спосіб ведення аналітики для підприємств та взаємодії зі споживачами",
            }
        ]
    }),

}
</script>

<style scoped>

.h1_for_mobile {
    font-size: 58px;
    font-weight: 700;
    font-style: normal;
    line-height: 1.23;
    text-transform: uppercase;
    background: linear-gradient(180deg, #44D0D0 0%, #807CF4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.h1_for_desktop {
    font-size: 72px;
    font-weight: 700;
    font-style: normal;
    line-height: 1.23;
    text-transform: uppercase;
    background: linear-gradient(180deg, #44D0D0 0%, #807CF4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.h2_for_momile {
    font-family: 'Artegra Sans', sans-serif;
    font-weight: 600;
    font-size: 26px;
    line-height: 1.17;
    color: #0f0c56;
}

.h2_for_desktop {
    font-family: 'Artegra Sans', sans-serif;
    font-weight: 600;
    font-size: 42px;
    line-height: 1.17;
    color: #0f0c56;
}

.text_p_for_card {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.38;
  color: #564BA4;
  height: 90px;
}

.title_for_card {
font-family: 'Artegra Sans', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 21px;
line-height: 1.24;
letter-spacing: -0.005em;
color: #F49719;
height: 52px;
}



.bl_border_radius {
    border-radius: 300px 0 0 300px;
}

.card {
    height: 320px;
    box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}


.circle_width_picture {
    position: relative;
    width: 110px;
    height: 110px;
    background: linear-gradient(180deg, #7B83F1 0%, #46CED1 100%);
} 

.main_title_md {
  font-size: 72px;
}

.mt_cran {
    margin-top: 100px;
}
</style>