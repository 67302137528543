import Vue from 'vue'
import Router from 'vue-router'

// ниже импортируем наши компоненты
import Home from '../views/Home.vue'
import AboutMe from '../views/AboutMe.vue'
import FAQ from '../views/FAQ.vue'
import Projects from '../views/Projects.vue'
import OurPartners from '../views/Partners.vue'
import Vacancies from '../views/Vacancies.vue'
import Contacts from '../views/Contacts.vue'
import UserFormAPI from '../views/UserFormAPI.vue'
import Support from '../views/Support.vue'
import Oferta from '../views/Oferta.vue'

Vue.use(Router)

export default new Router({
    bace: process.env.BASE_URL,
    routes: [
        {
            path: '',
            component: Home
        },
        {
            path: '/about_me',
            component: AboutMe
        },
        {
            path: '/faq',
            component: FAQ
        },
        {
            path: '/projects',
            component: Projects
        },
        {
            path: '/partners',
            component: OurPartners
        },
        {
            path: '/vacancies',
            component: Vacancies
        },
        {
            path: '/contacts',
            component: Contacts
        },
        {
            path: '/form',
            component: UserFormAPI
        },
        {
            path: '/support-chat',
            name: 'Support',
            component: Support
        },
        {
            path: '/oferta',
            component: Oferta
        },
    ],
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    mode: 'history',
})