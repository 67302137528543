<template>
    <div class="vacancies mt-10 mb-6 mx-3" >
        <v-container>
            <v-row align="center">
                <v-col cols="12" class="text-center">
                    <h2
                    :class="{'title_h2_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}" 
                    class="title_h2_for_mobile"
                    >
                    Наші <span>вакансії</span>
                    </h2>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col class="text-center mx-auto mt-2" cols="8" md="3">
                    <hr class="title_hr ">
                </v-col>
            </v-row>

            <v-row align="center" class="mt-6" v-if="vacancies">
                
                <v-col cols="10"  v-for="vacancy in vacancies" :key="vacancy.index">
                    <h3
                        :class="{'title_h3_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}" 
                        class="title_h3_for_mobile"
                        >
                        <p class="vacancyTitle mb-4">{{vacancy.title}}</p>
                    </h3>
                    <h4
                        :class="{'title_h4_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}" 
                        class="title_h4_for_mobile"
                    >
                        <p class="vacancySubTitle">{{vacancy.subtitle}}</p>
                    </h4>
                    <div class="text-sm-right"> 
                        <v-btn
                        small
                        depressed
                        color="white" 
                        @click="vacancy.msg =! vacancy.msg">Докладніше</v-btn> 
                    </div>
                    <div>
                        <v-alert 
                            :value="vacancy.msg"
                            transition="scale-transition">
                            <div v-for="desc in vacancy.description" :key="desc.index">
                                {{desc}}
                            </div>
                            
                        </v-alert>
                    </div>  
                </v-col>
            </v-row>

            <v-row align="center" class="mt-6" v-else>
                <v-col cols="12" class="text-center">
                    <h4
                    :class="{'title_h4_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}" 
                    class="title_h4_for_mobile"
                    >
                    <p>Нажаль, відкритих вакансій зараз немає</p>
                    </h4>
                </v-col>
            </v-row>    
        </v-container>
    </div>
</template> 



<script>

export default {
  
    name: 'Vacancies',
    data: ()=> ({
        vacancies:[
            {
                title:'Back-end developer',
                subtitle:'Провідна IT-компанія України шукає талановитого Back-end розробника з досвідом у розробці мобільних додатків!',
                description:
                    [
                        '   Якщо ви круто розбираєтеся з PHP, маєте досвід роботи з Laravel, чітко підтримуєте комунікацію з командою, мрієте зробити кар’єру та своїми знаннями модернізувати державу – ми чекаємо на ваше резюме!',
                        '   Що ми пропонуємо: ',
                        '- конкурентну заробітну плату за відпрацьований час;',
                        '- відсутність обмежень на ваші ініціативи;',
                        '- премії та бонуси за повноцінну віддачу проекту та принципам компанії;',
                        '- необмежену можливість кар’єрного зростання з відповідним зростанням заробітної плати;',
                        '- роботу за контрактом чи офіційне працевлаштування.',
                        'Зацікавила пропозиція? Резюме надсилайте на e-mail: marina.vanyushenko@it-serve.net'
                    ],
                msg: false
                
            },
            /*{
                title:'Помічник юриста',
                subtitle:'Шукаємо амбіціозного помічника',
                description:
                    ['test'],
                msg: false
            },*/ 
        ],
        
        
    }),
}
</script>

<style scoped>
    .vacancyTitle{
        color: #F49719;
    }
    .vacancySubTitle{
        color:#0f0c56;

    }
    .fade-enter-active, .fade-leave-active{
		transition: opacity 3.0s ease-out;
	}
	.fade-enter, .fade-leave-to{
		opacity: 0;
	}
    
</style>

   