<template>
    <div class="faq mt-15 mb-15">
        <v-row class="title">
           <v-col cols="12">
              <v-row align="center">
                <v-col cols="12" class="text-center">
                    <h2 
                    :class="{'title_h2_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}" 
                    class="title_h2_for_mobile"
                    >
                    <span>Безкоштовно &mdash; це</span>
                    </h2>
                </v-col>
                </v-row>
                <v-row align="center">
                    <v-col class="text-center mx-auto mt-2" cols="8" md="3">
                        <hr class="title_hr ">
                    </v-col>
                </v-row>
                <v-col cols="12" class="text-center mt-n2">
                        <h2 
                        :class="{'title_h2_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}" 
                        class="title_h2_for_mobile"
                        >
                        можливо
                        </h2>
                </v-col>
            </v-col>
        </v-row>

        <v-row justify="center" class="mx-3 mt-10 ">
                <v-col  v-for="card in cards_1" :key="card.index" cols="12" sm="8" md="5" lg="3" class="card card_faq mt-10 mx-md-5">
                    <v-row justify="center">
                        <v-col cols="12" class="text-center" @click.stop="card.id = !card.id">
                                <v-row justify="center" class="ma-auto">
                                    <v-col v-show="card.id" cols="8" class="mt-8">
                                        <div 
                                        :class="{'circle_width_picture_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                                        class="circile circle_width_picture mx-auto">
                                            <img class="img_center_positioning" :src="card.img" alt="">
                                        </div>
                                    </v-col>
                                 <v-col 
                                    v-show="!card.id"
                                    >
                                    <v-row class="text_for_card">
                                        <v-col>
                                            <v-row class="text-right">
                                                <v-col cols="12">
                                                    <v-btn icon text @click.stop="card.id = !card.id" class="text-right">
                                                        <v-icon 
                                                            class="text-right"
                                                            color="white"
                                                            >
                                                            mdi-close
                                                        </v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row class="text-center" align="center">
                                                <v-col cols="11" md="10" class="mx-auto">
                                                    <p>
                                                        {{ card.text }}
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                 </v-col>
                                </v-row>
                                <v-row justify="center" class="mx-auto d-flex align-center text-center title_for_card">
                                    <v-col cols="12" class="">
                                         <h4 v-html="card.title" class="title_h4">
                                         </h4>
                                     </v-col>  
                                </v-row>
                        </v-col>
                    </v-row>
                </v-col>    

        </v-row>
       
        <v-row justify="center">
            <v-col cols="12" class="mt-15">
                <h3 
                :class="{'h3_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                class="h3_for_mobile text-3rem text-center text-bold title_h2_O_S">
                   Яку банківську систему Ви застосовуєте?
                </h3>
            </v-col> 
            <v-col cols="4" md="2">
                <hr class="hr_yellow mt-2">
            </v-col>
        </v-row>

        <v-row  justify="center" class="mx-3 mt-10 mb-16">
                <v-col v-for="card in cards_banking" :key="card.title" cols="12" sm="8" md="5" lg="3" class="mt_100px mx-md-5">
                    <v-row justify="center">
                        <v-col cols="10" class="text-center height_card">
                                 <v-row justify="center" class="mx-auto">
                                    <v-col cols="8" class="mt-n16">
                                        <a :href="card.link" target="_blank">
                                            <div class="circile circle_width_picture_bank mx-auto">
                                                <img class="img_center_positioning" :src="card.img" alt="">
                                            </div>
                                        </a>
                                    </v-col>
                                 </v-row>
                                <v-row justify="center" class="mx-auto  text-center">
                                    <v-col cols="10" class="mt-7">
                                        <h4 class="title_h4">
                                            {{ card.title }}
                                        </h4>
                                    </v-col>
                                </v-row>
                        </v-col>
                    </v-row>
                </v-col>    
        </v-row>

        
    </div>
</template>

<script>
import lamp from '../assets/faq_img/lamp.png'
import bank_card from '../assets/faq_img/bank_card.png'
import list from '../assets/faq_img/list.png'
import smartphone from '../assets/faq_img/smartphone.png'
import rupor from '../assets/faq_img/rupor.png'
import iPay from '../assets/faq_img/iPay.png'
import portmone from '../assets/faq_img/portmone.png'
import liqpay from '../assets/faq_img/liqpay.png'

export default {
    name: 'FAQ',
    data:() => ({
        showInformCard: false,
        cards_1: [
            {
                id: 1,
                img: lamp,
                title: 'Безкоштовно &mdash; це як?',
                text: "IT SERVE має унікальну розробку, захищену авторським правом яку передає в користування підприємству на не обмежений термін. Ми працюємо, використовуючи європейський та американський досвід, який полягає в залученні нових абонентів до банківських систем.",
            },
             {
                id: 2,
                img: bank_card,
                title: 'Яка комісія для абонента?',
                text: "Комісія для абонента буде така ж сама як і в будь-якому банку, коли ми працюємо через додаток то банк віддає частину свого прибутку на користь компанії, а ми таким чином маємо гроші на розробку та  технічний супровід вашого підприємства через наші програми."
            },
             {
                 id: 3,
                img: list,
                title: 'Чи потрібен тендер?',
                text: "Проведення тендеру не потрібне, тому що наша компанія розробляє програмне забезпечення для підприємства на безоплатній основі та передає в користування на необмежений період."
            },
            {
                id: 4,
                img: smartphone,
                title: 'Конфіденційність?',
                text: "IT Serve отримує доступ до інформації абонента тільки у разі, якщо абонент надає дозвіл, згідно з політикою конфіденційності та пройде ідентифікацію."
            },
             {
                 id: 5,
                img: rupor,
                title: 'Маркетинг?',
                text: "Нашими спільними зусиллями з підприємствами ми розробляємо стратегію маркетингу. Від активності підприємства залежить загальний успіх підприємства. Підприємство повинно використовувати усі свої інформаційні канали за для підвищення ефективності."
            },
             {
                 id: 6,
                img: lamp,
                title: 'Безкоштовно &mdash; це як?',
                text: "IT SERVE має унікальну розробку, захищену авторським правом яку передає в користування підприємству на не обмежений термін. Ми працюємо, використовуючи європейський та американський досвід, який полягає в залученні нових абонентів до банківських систем."
            },
        ], 
        cards_banking: [
           {
               img: liqpay,
               title: 'ПриватБанк',
              
           },
           {
               img: portmone,
               title: 'Portmone.com',
               
           },
           {
               img: iPay,
               title: 'iPay.ua',
              
           }
       ]
    }),

    
}
</script>

<style scoped>
.circle_width_picture {
    position: relative;
    width: 210px;
    height: 210px;
    background: linear-gradient(325.23deg, #F59F16 13.53%, #FBC50A 90.98%);
}

.circle_width_picture_desktop {
    background: linear-gradient(132.23deg, #42D3CF 13.5%, #807DF3 88.83%);
}

.circle_width_picture_bank {
    position: relative;
    width: 165px;
    height: 165px;
}

.text_for_card {
    height: 300px;
    background: #F5A116;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.7;
}

.title_for_card {
    height: 60px;
    background: #fff;
}

.card {
    height: 360px;
    box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.card_bank {
    height: 280px;
    box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.mt_100px {
    margin-top: 100px !important;
}

.card_faq:hover h4 {
        color: #F49719;
    }

    .card_faq:hover .circle_width_picture_desktop {
        background: linear-gradient(132.23deg, #F59E17 13.5%, #FCC50A 88.83%);
    }

    .card_faq:hover, 
    .circle_width_picture_bank:hover {
       -webkit-box-shadow: 0px 0px 27px 6px rgba(0, 0, 0, 0.25) ; 
        -moz-box-shadow: 0px 0px 27px 6px rgba(0, 0, 0, 0.25) ; 
        box-shadow: 0px 0px 27px 6px rgba(0, 0, 0, 0.25); 
    }
</style>