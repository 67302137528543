<template>
    <div class="projects mt-15 mb-16 mx-3">
        <v-container>
            <v-row align="center">
            <v-col cols="12" class="text-center">
                <h2
                :class="{'title_h2_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}" 
                class="title_h2_for_mobile"
                 >
                 Наші <span>Проекти</span>
                 </h2>
            </v-col>
            </v-row>
            <v-row align="center">
                <v-col class="text-center mx-auto mt-2" cols="8" md="3">
                    <hr class="title_hr ">
                </v-col>
            </v-row>

            <v-row 
            align="center"
            v-for="card in cards" 
            :key="card.index" 
            class="mt-11 mt-lg-16 text-center d-flex flex-lg-wrap-reverse"
            >
                <v-col cols="12" md="6" :class="card.order1" class="hidden-sm-and-down">
                    <v-row align="center">
                        <v-col>
                            <h3 
                            class="h3_for_mobile text-3rem text-left text-bold title_h2_O_S">
                                {{ card.title }}
                            </h3>
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col cols="12" class="mt-5">
                            <p 
                            v-for="text in card.texts" 
                            :key="text.index" 
                            :class="{'text_p_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                            class="text-left text_p_for_mobile"
                            v-html="text.text">
                            </p>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" md="5" class="hidden-md-and-up">
                    <v-row align="center">
                        <v-col>
                            <h3 
                            :class="{'h3_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                            class="h3_for_mobile text-3rem text-left text-bold title_h2_O_S">
                                {{ card.title }}
                            </h3>
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col cols="12" class="mt-5">
                            <p 
                            v-for="text in card.texts" 
                            :key="text.index" 
                            :class="{'text_p_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                            class="text-left text_p_for_mobile">
                                {{ text.text }}
                            </p>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" md="6"
                :class="card.order2"
                class="mt-10"
                >
                    <v-row align="center">
                        <v-col
                        v-for="img in card.imgs"
                        :key="img.index"
                        class="px-10"
                        >
                            <img :width="img.width" :src="img.img" alt="">
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import dodatoc1 from '../assets/project_img/dodatoc1.png'
import dodatoc2 from '../assets/project_img/dodatoc2.png'
import display from '../assets/project_img/display.png'
import chat_bot from '../assets/project_img/chat_bot.png'

export default {
    name: 'Projects',
    data: () => ({
        
        cards: [
            {
                title: "Smart Region",
                order1: 'order2',
                order2: 'order1',
                imgs: [
                    {
                        img: dodatoc1,
                    }
                ],
                texts: [
                    {
                        text: "Сучасний онлайн сервіс, який не має аналогів! Smart Регіон &mdash; платформа, яка дозволяє поєднати в собі різноманітні послуги Вашого регіону."
                    },
                    {
                        text: "- оплата комунальних послуг;"
                    },
                    {
                        text: "- передача показань лічильників;"
                    },
                     {
                        text: "- оплата проїзду у громадському транспорті;"
                    },
                     {
                        text: "- співпраця з Центрами надання адміністративних послуг;"
                    },
                     {
                        text: "- запровадження Гарячої лінії з міським головою і головою місцевої державної адміністрації;"
                    },
                     {
                        text: "- а також багато інших послуг."
                    },
                     {
                        text: "Smart Регіон - електронна система, що дозволить мешканцям зручно й оперативно отримувати  комунальні, адміністративні та сервісні послуги міста й області. Тепер оплачувати комунальні послуги, передавати показання лічильників, отримувати інформацію про планові сервісні роботи, а також оформляти необхідні довідки можна в одному місці. Швидше реєструйся й живи по-новому!"
                    },
                ]
            },
            {
                title: "Особистий кабінет підприємства:",
                order1: 'order1',
                order2: 'order2',
                imgs: [
                    {
                        img: display,
                    }
                ],
                texts: [
                    {
                        text: "Для всіх підприємств, з якими ми співпрацюємо, ми створюємо особистий кабінет. За його допомогою підприємства з легкістю зможуть збирати аналітичні дані. Оператори абонентського відділу зможуть відстежувати борги споживачів послуг, формувати заявки на отримання необхідних сервісних і адміністративних послуг, створювати Push-повідомлення, а також відповідати на всі питання споживачів послуг."
                    },
                ]
            },
            {
                title: "Мобільні додатки для комунальних підприємств",
                order1: 'order2',
                order2: 'order1',
                imgs: [
                    {
                        img: dodatoc2,
                    }
                ],
                texts: [
                    {
                        text: "Забудьте про черги в касах, про чат-боти, затримки і неузгодження при передачі показань лічильників. Відтепер за допомогою наших мобільних додатків оплачувати комунальні послуги, передавати показання лічильників, отримувати інформацію про планові сервісні роботи, а також оформляти необхідні довідки можна набагато зручніше. Майбутнє вже настало!"
                    },
                ]
            },
            {
                title: "Чат-боти",
                order1: 'order1',
                order2: 'order2',
                imgs: [
                    {
                        img: chat_bot,
                    }
                ],
                texts: [
                    {
                        text: "За бажанням підприємств наша команда створює чат-боти у будь-яких месенджерах під необхідні завдання. Чат-боти є додатковим каналом для передачі показань лічильників та зв’язку з абонентами (споживачами послуг)."
                    },
                ]
            },
        ]
    
    })
}
</script>

<style scoped>
.order1 {
    order: 0;
}

.order2 {
    order: 1;
}
</style>