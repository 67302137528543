<template>
<v-app>
  <HeaderMobile/>
  <hr class="line_for_header hidden-md-and-down">
  <v-main 
    class="mt_110px"
    :class="{'mt_170px': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md}"
    >
    <router-view/>
  </v-main>
  <Footer/>
  
</v-app>
</template>

<script>
import HeaderMobile from './components/HeaderMobile.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',

  components: {
   HeaderMobile,
   Footer
  },

  data: () => ({
    //
  }),
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@font-face {
    font-family: 'Artegra Sans';
    src:
        url('./assets/fonts/ArtegraSans.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

body {
  overflow-x: hidden !important;
}

.row {
  margin: 0 !important;
}

button, span, p, a, div {
  padding: 0  !important;
  
}

p {
  margin: 0  !important;
}

header {
  height: auto !important;
}

.line_for_header {
  position: absolute;
  width: 100%;
  z-index: 100;
  margin-top: 85px;
  height: 1px;
  border: none;
  background: #b6b8b9;

  
}

main {
  padding: 0 !important;
}

.v-window__prev, .v-window__next {
  background: none !important;
  margin: 0 5px !important;
}

.v-app-bar--fixed,
.v-footer--fixed {
  position: relative !important;
}


a::before {
  background: transparent !important;
}

.title_h2_for_mobile {
  font-family: 'Artegra Sans', sans-serif;
  font-size: 52px;
  font-weight: 600;
  line-height: 1.17;
  color: #0f0c56;
}

.title_h2_for_desktop {
  font-family: 'Artegra Sans', sans-serif;
  font-size: 72px;
  font-weight: 600;
  line-height: 1.17;
  color: #0f0c56;
}

.text_p_for_mobile {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.38;
  color: #564BA4
}

.text_p_for_desktop {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.38;
  color: #564BA4
}




span {
    color: #F49719;
}

.v-carousel__controls {
  display: none !important;
}

.style {
  height: auto !important;
}

.v-icon {
  color: #F49719;
}



.title_hr {
    border: none;
    height: 4px;
    background: linear-gradient(180deg, #45CFD1 0%, #807CF4 100%);
    opacity: 0.6;
}

.title_h4 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 21px;
  line-height: 1.38;
  text-align: center;
  color: #0f0c56
}

.card {
    box-shadow: 0px 0px 27px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.h3_for_mobile {
  font-family: 'Artegra Sans', sans-serif;
   font-size: 40px;
  font-weight: 600;
  line-height: 1.17;
  color: #0f0c56;
}

.h3_for_desktop {
  font-family: 'Artegra Sans', sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.17;
  color: #0f0c56;
}

.h3_for_desktop_white {
  font-family: 'Artegra Sans', sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.17;
  color: #fff;
}

.w_100 {
    width: 100%;
}

.mt_110px {
  margin-top: -110px !important;
}
.mt_170px {
  margin-top: -170px !important;
}

.main_margin {
    margin-top: 70px;
    margin-bottom: 70px;
}

.yellow_btn {
  border:none;
  background: linear-gradient(92.46deg, #FBA802 16.53%, #FCBD22 55.38%, #FBCD39 98.62%);
  box-shadow: 0px 0px 7px 4px rgba(252, 170, 6, 0.3);
}

.yellow_btn_text {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.24;
  text-align: center;
  text-transform: none;
  color: #fff;
}

.text_for_navigation {
  font-family: 'Artegra Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 1.24;
  text-align: center;
  color: #0F0C56;
}

.hr_yellow {
  border: 2px solid #FCCA34;
  background: #FCCA34;
}

.chevron_color {
  color: #F49719;
}

.circile {
  border: none;
  border-radius: 50%;
}

.img_center_positioning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.bg_grad {
    background: linear-gradient(180deg, #2A6E93 0%, #4645A4 100%);
}

.bg_white {
  background: #ffffff;
}




</style>
