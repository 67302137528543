import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import Tawk from 'vue-tawk/src/lib'

Vue.config.productionTip = false

Vue.use(Tawk, {
  tawkSrc: 'https://tawk.to/chat/61bc2323c82c976b71c1d9f7/1fn3eih86'
}) 
window.Tawk_API.onLoad = function () {
  window.Tawk_API.maximize ();
};

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
