<template>
    <v-container>
            <v-row class="mt-md-15" justify="center">
                <v-col cols="12" md="8"
                 :class="{'form_shadow': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                 >
                    <v-row justify="center">
                        <v-col cols="12" class="mt-15 mt-md-8">
                            <h3 
                            :class="{'h3_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                            class="h3_for_mobile text-3rem text-center text-bold title_h2_O_S">
                                Залишити заявку
                            </h3>
                        </v-col> 
                            <v-col cols="4" md="2">
                                <hr class="hr_yellow mt-2">
                            </v-col>    
                    </v-row>

                    <form class="form" id="mailForm" action="https://formspree.io/f/xwkjpwgz" method="POST">
                        <v-row class="d-flex justify-space-between mt-md-5">
                            <v-col cols="6" class="d-flex flex-column justify-space-between px-2 px-md-10 mt-5">
                                <input type="text" id="name" name="name" placeholder="Ім’я" class="form-control"> 
                                <input type="number" id="phone" name="phone" placeholder="Телефон" class="form-control mt-5">
                            </v-col>
                            <v-col cols="6" class="d-flex flex-column justify-space-between px-2 px-md-10 mt-5">
                                <input type="text" name="company" id="company" placeholder="Назва Компанії" class="form-control">
                                <input type="email" id="email" name="email" placeholder="E-mail" class="form-control mt-5">  
                            </v-col>
                            <v-col cols="12" class="px-2 px-md-10 mt-5">
                                <input name="message" id="message" placeholder="Повідомлення" class="form-control w_100">
                            </v-col>
                            <v-col cols="12" md="12" class="text-center mt-5 mb-md-10">
                                <v-btn dark large outlined rounded type="submit" id="sendMail" class="yellow_btn px-11 py-3 btn btn-success"> 
                                    <span class="yellow_btn_text"> 
                                        Відправити 
                                    </span>
                                </v-btn>
                            </v-col>
                        </v-row>    
                    </form>         
                </v-col>  
            </v-row>
    </v-container>           
</template>

<script>
export default {
    name: "UserFormAPI"
}
</script>

<style scoped>
.form_shadow {
    box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

input, textarea {
    font-size: 21px;
    color: #F49719;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px #48CCD2;
    border: none;
    border-radius: 100px;
    outline:none;
}

::-webkit-input-placeholder {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.36;
    color: #45D0D0;
    padding-left: 10px;
}
::-moz-placeholder {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.36;
    color: #45D0D0;
    padding-left: 10px;
}
:-moz-placeholder {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.36;
    color: #45D0D0;
    padding-left: 10px;
}
:-ms-input-placeholder {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.36;
    color: #45D0D0;
    padding-left: 10px;
}
</style>