<template>
    <div class="about_me mt-15 mb-16">
        <v-container>
            <v-row align="center">
                <v-col cols="12" class="text-center">
                    <h2 
                    :class="{'title_h2_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}" 
                    class="title_h2_for_mobile"
                    >
                    Наша <span>Компанія</span>
                    </h2>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col class="text-center mx-auto mt-2" cols="8" md="3">
                    <hr class="title_hr ">
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col cols="11" md="8" class="mt-5 ">
                    <p 
                    v-for="text in texts_info" 
                    :key="text.index" 
                    :class="{'text_p_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                    class="text-center text_p_for_mobile mt-5">
                        {{ text.text }}
                    </p>
                </v-col>
            </v-row > 

            <v-row justify="center">
                <v-col cols="12" class="mt-15">
                    <h3 
                    :class="{'h3_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                    class="h3_for_mobile text-3rem text-center text-bold title_h2_O_S">
                        Процес співпраці з підприємствами
                    </h3>
                </v-col> 
                <v-col cols="4" md="2">
                    <hr class="hr_yellow mt-2">
                </v-col>
            </v-row> 

            <v-row align="center" justify="center" class="mt-md-15 mx-xs-2 mt-xs-8  text-center" >
                <v-col cols="12" md="10">
                    <img  class="w_100 hidden-sm-and-down" src="../assets/schema_img/schema.png" alt="">
                    <img  class="w_100 hidden-md-and-up" src="../assets/schema_img/schema_mobile.png" alt="">
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col cols="12" class="mt-15">
                    <h3 
                    :class="{'h3_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                    class="h3_for_mobile text-3rem text-center text-bold title_h2_O_S">
                        Наші  Досягнення
                    </h3>
                </v-col> 
                <v-col cols="4" md="2">
                    <hr class="hr_yellow mt-2">
                </v-col>
            </v-row> 

            <v-row justify="center" class="mx-2 mt-10 d-flex justify-space-between">
                    <v-col
                    v-for="card in cards" 
                    :key="card.title"
                    cols="12" sm="8" md="4" 
                    class="card mt-15 mx-md-n5">
                        <v-row justify="center">
                            <v-col cols="10" class="text-center height_card">
                                    <v-row justify="center" class="mx-auto">
                                        <v-col cols="6" class="mt-n13">
                                            <div class="circile circle_width_picture mx-auto">
                                                <img class="img_center_positioning" :src="card.img" alt="">
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center" class="mx-auto  text-center">
                                        <v-col cols="10" class="mt-5">
                                            <h4 class="title_h4">
                                                {{ card.title }}
                                            </h4>
                                        </v-col>
                                        <v-col cols="12">
                                            <h3 class="numbers_achievements mb-7">
                                                {{ card.numbers }}
                                            </h3>
                                        </v-col>  
                                    </v-row>
                            </v-col>
                        </v-row>
                    </v-col>    
            </v-row>

            <v-row justify="center">
                <v-col cols="12" class="mt-15">
                    <h3 
                        :class="{'h3_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                        class="h3_for_mobile text-3rem text-center text-bold title_h2_O_S">
                        Наша  Команда
                    </h3>
                </v-col> 
                <v-col cols="4" md="2">
                    <hr class="hr_yellow mt-2">
                </v-col>
            </v-row> 

        </v-container>

        <v-row
        :class="{'bg_grad': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
        class="mt-16" 
        >
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" class="mt-15">
                        <h3 
                            :class="{'h3_for_desktop_white': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                            class="h3_for_mobile text-3rem text-center text-bold title_h2_O_S">
                                Наші переваги
                        </h3>
                    </v-col> 
                    <v-col cols="4" md="2">
                        <hr class="hr_yellow mt-2">
                    </v-col>
                </v-row>

                <v-carousel hide-delimiters height="auto" class="hidden-md-and-up">
                    <v-carousel-item
                        v-for="card in cards_advantages"
                        :key="card.title"
                    >
                        <v-row justify="center" class="mx-2 mt-9">
                            <v-col cols="12" sm="8" md="3" class="card_advantages">
                                <v-row justify="center">
                                    <v-col cols="10" class="text-center height_card">
                                        <v-row justify="center" class="mx-auto  text-center">
                                            <v-col cols="10" class="mt-8">
                                                <h4 class="title_h4 title_h4_advantages">
                                                    {{ card.title }}
                                                </h4>
                                            </v-col>
                                            <v-col cols="12">
                                                <p class="text-center text_p_for_mobile  text_p_for_mobile_advantages mt-5">
                                                    {{ card.text }}
                                                </p>
                                                <p class="link text-center text_p_for_mobile"> {{ card.text_link }} </p>
                                            </v-col>  
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>    
                        </v-row>  
                    </v-carousel-item>
                </v-carousel> 

                <v-row 
                    class="hidden-sm-and-down" 
                    justify="space-around"
                >
                    <v-col 
                        v-for="card in cards_advantages" 
                        :key="card.index" 
                        cols="3"
                    >
                        <v-row  justify="center" class="">
                            <v-col cols="12" class="card_advantages_desktop mt-15 mb-15 text-center">
                                <v-row justify="center" class="mx-auto  text-center">
                                    <v-col cols="10" class="mt-5">
                                        <h5 class="title_for_card"
                                            color="#fff"
                                        >
                                            {{ card.title }}
                                        </h5>
                                    </v-col>
                                    <v-col cols="12" class="px-2 mt-3">
                                        <p class="text_p_for_card">
                                            {{ card.text }}
                                            <br>
                                            <span class="link text-center text_p_for_card"> 
                                                {{ card.text_link }} 
                                            </span>
                                        </p>   
                                    </v-col>  
                                </v-row>
                            </v-col>  
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
    </div>
</template>

<script>
import people from '../assets/people.png'
import coins from '../assets/coins.png'
import done from '../assets/done.png'

export default {
    name: 'AboutMe',

    data: () => ({
       texts_info: [
           {
               text: "Наша команда співпрацює з підприємствами в газовій та енергетичній сфері, з постачальниками теплової енергії та компаніями, що спеціалізуються на водопостачанні та водовідведенні, вивезенні сміття.",
           },
           {
               text: "Наші розробки успішно впроваджені на 15 підприємствах. Ми представлені в багатьох областях України - від Харкова і до Львова.",
           },
           {
               text: "Більш 500 000 осіб вже сьогодні активно використовують наші мобільні додатки.",
           },
           {
               text: "Головною перевагою IT Serve є те, що всі розробки та технології безкоштовно розробляються та передаються в експлуатацію нашим партнерам на безоплатній основі, що виключає необхідність проведення тендерів чи конкурсів.",
           }
       ],
       cards: [
            {
                title: 'Користувачі',
                img: people,
                numbers: "783 971"
            },
             {
                title: 'Оплати',
                img: coins,
                numbers: "1 121 756"
            },
             {
                title: 'Передані показання',
                img: done,
                numbers: "1 150 029"
            }
        ],
        cards_advantages: [
             {
                title: 'Наші партнери',
                text: 'Один лише список наших партнерів може говорити про рівень довіри та якість наших робіт. Чесна і відкрита співпраця - запорука нашого успіху',
            },
             {
                title: 'Безкоштовно',
                text: 'Для співпраці з нами, нема потреби проводити тендери та подібного роду конкурси. Розробка, інтеграція, а також обслуговування наших проєкт абсолютно безкоштовно віддається в використання підприємствам',
            },
             {
                title: 'Наша команда',
                text: 'За роки роботи з різними комунальними підприємствами, банками, державними органами, а також міською владою України, наша команда здобула безцінний досвід і знання в організації співпраці з такими партнерами',
            }
        ],
    }),
}
</script>

<style scoped>

.circle_in_element {
    position: relative;
    width: 66px;
    height: 66px;
    background: linear-gradient(132.81deg, #F49719 7.51%, #FDCE07 94.09%);
    box-shadow: 0 0 0 6px rgba(244, 151, 25, 0.2);
    margin-left: 25px;
    margin-top: 13.5px;
}

.text_element_schema {
    margin: auto;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.38;
    color: #F49719;
}

.element {
    width: 300px;
    height: 70px;
    border: 1px solid #898F96;
    box-sizing: border-box;
    border-radius: 45px;
   
}

.circle_in_element {
    width: 39px;
    height: 39px;
}

.circle_width_picture {
    position: relative;
    width: 110px;
    height: 110px;
    background: linear-gradient(325.23deg, #F59F16 13.53%, #FBC50A 90.98%);
}

.numbers_achievements {
     font-family: 'Open Sans', sans-serif;
     font-weight: 700;
     font-size: 64px;
     line-height: 1.36;
     text-align: center;
     text-transform: uppercase;
    background: linear-gradient(180deg, #44D0D0 0%, #807CF4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     margin-top: 5px;
 }

 .card_advantages {
    height: 300px;
    background: linear-gradient(180deg, #4745A5 13.96%, #4EC4D6 152.4%);
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
 }

 .text_p_for_card {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.38;
  color: #564BA4;
  height: 90px;
}

.title_for_card {
font-family: 'Artegra Sans', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 21px;
line-height: 1.24;
letter-spacing: -0.005em;
color: #F49719;
}

.card_advantages_desktop {
    height: 280px;
    box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background: #fff;
}

 .title_h4_advantages,
 .text_p_for_mobile_advantages {
     color: #fff;
 }

 .link {
    color:  #F49719;
    text-align: center;
}

.bg_grad {
    background: linear-gradient(180deg, #2A6E93 0%, #4645A4 100%);
}



    

</style>

