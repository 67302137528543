<template>
 <div class="header">
        <v-app-bar app color="accent-4" prominent class="mx-auto overflow-hidden transparent px-4 pt-2 py-md-5"  flat  height="200px">
          <v-container class="d-flex justify-space-between">
            <router-link :to="mainRoute">
                <v-img alt="IT_Serve_Logo" class="shrink" contain src="../assets/Logo.png" height="50">  
                </v-img>
            </router-link>

            <v-spacer></v-spacer>

            <v-toolbar-items class="hidden-md-and-down align-center header_img">
                    <router-link 
                        v-for="item in menuItems" 
                        :key="item.index" 
                        router :to="item.route"
                        class="link_for_desktop mr-6" 
                    > 
                        {{ item.title }} 
                    </router-link>
                    <v-col class="ml-10">
                      <v-btn  outlined rounded :to="form" dark class="px-8 py-3 btn"> 
                        <span class="link_for_desktop"> 
                            Залишити заявку 
                        </span>
                      </v-btn>
                    </v-col> 
                </v-toolbar-items>
                

            <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-lg-and-up icon"
            ></v-app-bar-nav-icon>
          </v-container>
        </v-app-bar>
     
     
    <v-navigation-drawer v-model="drawer" absolute right temporary height="auto" width="100%">
        <v-row class="pt-6 px-6">
            <router-link :to="mainRoute">
                <v-img alt="IT_Serve_Logo" class="shrink" contain src="../assets/Logo.png" height="50" width="120">  
                </v-img>
            </router-link>

             <v-spacer></v-spacer>

            <v-btn icon text @click.stop="drawer = !drawer">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
        </v-row>
      <v-list nav dense class=" text-center">
        <v-list-item-group v-model="group" class="text-center">
          <v-list-item v-for="item in menuItems" :key="item.index" router :to="item.route" class="text-center">
            <v-list-item-title class="text-center mt-2 mb-2">
              <span class="text_for_navigation"> {{ item.title }} </span>
            </v-list-item-title>
          </v-list-item>    
        </v-list-item-group>
        <v-btn large outlined rounded :to="form" class="yellow_btn px-11 py-3 mt-10 mb-10" > 
          <span class="yellow_btn_text"> 
              Залишити заявку 
          </span>
        </v-btn>
      </v-list>
    </v-navigation-drawer>
 </div>
    

    

</template>

<script>
export default {
    name: "HeaderMobile",
    data: () => ({
      form: "/form",
      heigth: 200,
      drawer: false,
      group: null,
      mainRoute: '/',
      menuItems: [
            {
                title: 'Про нас',
                route: '/about_me'
            },
             {
                title: 'Найчастіші питання',
                route: '/faq'
            },
             {
                title: 'Проекти',
                route: '/projects'
            },
             {
                title: 'Партнери',
                route: '/partners'
            },
            {
                title: 'Вакансії',
                route: '/vacancies'
            },
             {
                title: 'Контакти',
                route: '/contacts'
            },
        ],
    }),

    watch: {
      group () {
        this.drawer = false
      },
    },
}
</script>

<style scoped>

.link_for_desktop {
    font-family: 'Artega Sans', sans-serif;
    color: #0F0C56;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.22;
    letter-spacing: 0;
    text-decoration: none;
    text-transform: none;
}

.btn {
  background: transparent;
  border: 1px solid #0F0C56;

}

.btn:hover {
  border: 1px solid #FCBD22;
  background: linear-gradient(92.46deg, #FBA802 16.53%, #FCBD22 55.38%, #FBCD39 98.62%) !important;
  box-shadow: 0px 0px 7px 4px rgba(252, 170, 6, 0.3);
}

.btn:hover span {
  color: #fff;
}

.btn::after {
  content: '';
  height: 610px;
  width: 692px;
  position: absolute;
  top: -415px;
  right: -380px;
  background: linear-gradient(56.75deg, #45D0D0 34.01%, #807CF4 94.73%);
  border-radius: 50%;
  pointer-events: none;
  z-index: -1;
}

.icon::after {
  content: '';
  height: 175px;
  width: 190px;
  position: absolute;
  background: linear-gradient(56.75deg, #45D0D0 34.01%, #807CF4 94.73%);
  border-radius: 0 0 0 100%;
  z-index: -1;
}

.height200 {
  height: 200px !important;
}


</style>