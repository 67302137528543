<template>
    <v-footer app class="bg_color mt-16">
        <v-container>
            <div class="footer_mobile hidden-md-and-up">
                <v-row justify="center">
                    <v-col cols="10" class="">
                        <router-link :to="mainRoute">
                            <v-img alt="IT_Serve_Logo" class="shrink mx-auto" contain src="../assets/logo_footer.png" height="139" width="124">  
                            </v-img>
                        </router-link>
                    </v-col>
                    <v-col cols="12" class="text-center mt-8">
                        <h6>Контакти</h6>
                        <p class="mt-2">Тел.: +380630210311</p>
                        <p class="mt-2">contact@it-serve.net</p>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center mt-2">
                        <h6>Показати нас на карті</h6>
                        <img class="mt-6" width="45" height="64" src="../assets/loca.png" alt="">
                    </v-col>
                    <v-col cols="10" md="12" class="text-center text-md-left">
                        <h6 class="mt-4">Зв’язатися з нами</h6>
                        <v-btn large outlined rounded :to="form" class="yellow_btn px-12 py-4 mt-7"> 
                            <span class="yellow_btn_text"> 
                                Залишити заявку 
                            </span>
                        </v-btn>
                        <p class="mt-6 mb-3">www.it-serve.net</p>    
                    </v-col>
                </v-row>
            </div>
        </v-container>
        

        
        <v-container>
            <div class="footer_desktop hidden-sm-and-down my-10">
                <v-row>
                    <v-col cols="12">
                        <router-link :to="'/'"><img src="../assets/Logo.png" alt="IT Serve - logo"></router-link>
                    </v-col>
                </v-row>
                <v-row class="d-flex justify-space-between">
                    <v-col 
                        cols="3"
                        v-for="col in cols_footer" 
                        :key="col.index"
                    >
                        <h6 class="title_footer text-left ">
                            {{ col.title1 }}
                        </h6>
                        <v-row>
                            <v-col cols="1" class="mr-2">
                                <v-icon dense color="amber darken-1">
                                    mdi-phone
                                </v-icon>
                                <v-icon dense color="amber darken-1">
                                    mdi-email
                                </v-icon>
                            </v-col>
                            <v-col cols="10">
                                <p 
                                v-for="text in col.texts" 
                                :key="text.index"
                                class="text_in_footer text-left"
                                >
                                    {{ text.text }}
                                </p>
                            </v-col>
                        </v-row>
                        <!--<v-row class="mt-4">
                            <v-col cols="1" class="mr-2">
                                <v-icon dense color="amber darken-1">
                                    mdi-map-marker
                                </v-icon>
                            </v-col>
                            <v-col cols="10">
                               <h6 class="title_footer text-left pt-2">
                                    {{ col.title2 }}
                                </h6>
                            </v-col>
                        </v-row>-->
                    </v-col>
                    <v-col
                        cols="3"
                        class="pl-10"
                    >
                        <h6 class="title_footer text-left">
                            Інформація
                        </h6>
                        <v-row class="mt-4">
                            <v-col cols="1" class="mr-6">
                                <a href="https://www.facebook.com/itserveua" >
                                    <v-icon large dense color="amber darken-1">
                                        mdi-facebook
                                    </v-icon>
                                </a>
                            </v-col>
                            <v-col cols="1" class="mr-6">
                                <a href="https://instagram.com/it_serve.ua" >
                                    <v-icon large dense color="amber darken-1">
                                        mdi-instagram
                                    </v-icon>
                                </a>
                            </v-col>
                            <!-- <v-col cols="1" class="mr-2">
                                <a href="https://www.linkedin.com/company/it-serve-services/" >
                                    <v-icon large dense color="amber darken-1">
                                        mdi-linkedin
                                    </v-icon>
                                </a>
                            </v-col> -->
                            
                        </v-row>
                        <nav 
                        v-for="link in links"
                        :key="link.index"
                        class="navigation_footer"
                        >
                            <!--<router-link 
                            :to="link.route"
                            >-->
                            <div
                            @click="goToPage(link.route)"
                            >
                                {{ link.title }}
                            </div> 
                        </nav>
                    </v-col>
                    <v-col
                    cols="3"
                    v-for="info in informs" 
                    :key="info.index"
                    class="text-center"
                    >
                        <h6 class="title_footer text-left">
                            {{ info.title }}
                        </h6>
                        <p class="text_in_footer text-left">
                            {{info.text}}
                        </p>
                    </v-col>
                    <v-col cols="3" class="pl-15">
                        <h6 class="title_footer text-left">
                           Зв’язатися з нами 
                        </h6> 
                        <v-btn large outlined rounded dark :to="form" class="yellow_btn px-11 py-3"> 
                            <span class="yellow_btn_text btn_footer"> 
                                Залишити заявку 
                            </span>
                        </v-btn>    
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
    data:() => ({
      mainRoute: '/',
      form: "/form",
      cols_footer: [
          {
              title1: "Контакти",
              //title2: "Показати нас на мапі",
              texts: [
                  {text: "Тел.: +380630210311"},
                  {text: "contact@it-serve.net"},
                  //{text: "www.it-serve.net"},
              ]
          }
      ],
      links: [
            {
                title: 'Про нас',
                route: '/about_me'
            },
             {
                title: 'Найчастіші питання',
                route: '/faq'
            },
             {
                title: 'Проекти',
                route: '/projects'
            },
             {
                title: 'Партнери',
                route: '/partners'
            },
            {
                title: 'Вакансії',
                route: '/vacancies'
            },
             {
                title: 'Контакти',
                route: '/contacts'
            },
      ],
      informs: [
          {
              title: "Наша місія",
              text: "Полегшити життя людей шляхом створення найзручнішої та функціональної платформи для взаємодії громадян з усіма державними й комерційними підприємствами та органами",
          }
      ]
    }),
    
    methods: {
        goToPage(path) {
            this.$router.push({path: path})
        }
    }
}
</script>

<style scoped>
.bg_color {
    background: linear-gradient(0deg, #4745A5 37.06%, #2A6E93 107.44%);
}

h6 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 1.08;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.03em;
    color: rgba(255, 255, 255, 0.7);

}

.text_in_footer,
.navigation_footer a  {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.73;
    letter-spacing: -0.005em;
    color: rgba(255, 255, 255, 0.56);
    text-decoration: none;
}

.title_footer {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.08;
    letter-spacing: -0.005em;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.btn_footer {
    font-family: 'Artegra Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.36;
}

.size_icon {
    width: 14px;
    height: 14px;
}

a{
    
    text-decoration: none; 
   
}
a:hover {
    text-decoration: underline;
   }
</style>