<template>
    <div class="partners mt-15 mb-16 mx-3" >
        <v-container>
            <v-row align="center">
            <v-col cols="12" class="text-center">
                <h2
                :class="{'title_h2_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}" 
                class="title_h2_for_mobile"
                 >
                 Наші <span>Партнери</span>
                 </h2>
            </v-col>
            </v-row>
            <v-row align="center">
                <v-col class="text-center mx-auto mt-2" cols="8" md="3">
                    <hr class="title_hr ">
                </v-col>
            </v-row>

            <v-row 
            align="center"
            v-for="card in cards" 
            :key="card.index" 
            class="mt-11 mt-lg-16 text-center d-flex flex-lg-wrap-reverse"
            >
                <v-col cols="12" md="5" :class="card.order1" class="hidden-sm-and-down">
                    <v-row align="center">
                        <v-col>
                            <h3 
                            :class="{'h3_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                            class="h3_for_mobile text-3rem text-left text-bold title_h2_O_S">
                                {{ card.title }}
                            </h3>
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col cols="12" class="mt-5">
                            <p 
                            v-for="text in card.texts" 
                            :key="text.index" 
                            :class="{'text_p_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                            class="text-left text_p_for_mobile">
                                {{ text.text }}
                            </p>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" md="5" class="hidden-md-and-up">
                    <v-row align="center">
                        <v-col>
                            <h3 
                            :class="{'h3_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                            class="h3_for_mobile text-3rem text-left text-bold title_h2_O_S">
                                {{ card.title }}
                            </h3>
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col cols="12" class="mt-5">
                            <p 
                            v-for="text in card.texts" 
                            :key="text.index" 
                            :class="{'text_p_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}"
                            class="text-left text_p_for_mobile">
                                {{ text.text }}
                            </p>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" class="hidden-md-and-up">
                    <v-row align="center">
                        <v-col class="mt-3">
                            <v-carousel height="auto" :show-arrows="false">
                                <v-carousel-item
                                v-for="img in card.imgs"
                                :key="img.index" 
                                class="center"
                                >
                                <img class="mr-3 img_size" :src="img.img" alt="">
                                <img :src="img.img_2" alt="">
                                </v-carousel-item>
                            </v-carousel>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col 
                cols="6" 
                class="hidden-sm-and-down"
                :class="card.order2">
                    <v-row align="center">
                        <v-col
                        v-for="img in card.imgs"
                        :key="img.index"
                        >
                            <img :src="img.img" alt="">
                            <img :src="img.img_2" alt="">
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import diya from '../assets/partners_img/diya.png'
import kharkiv from '../assets/partners_img/kharkiv.png'
import khmelnitsk from '../assets/partners_img/khmelnitsk.png'
import lviv from '../assets/partners_img/lviv.png'
import noname from '../assets/partners_img/noname.png'
import rivne from '../assets/partners_img/rivne.png'
import ternopil from '../assets/partners_img/ternopil.png'
import zhytomyr from '../assets/partners_img/zhytomyr.png'
import teploenergetic from '../assets/partners_img/teploenergetic.png'
import kirovograd from '../assets/partners_img/kirovograd.png'
import koek from '../assets/partners_img/koek.png'
import kharkivoblenergo from '../assets/partners_img/kharkivoblenergo.png'
import kharkivenergozbut from '../assets/partners_img/kharkivenergozbut.png'
import kirovogradgaz from '../assets/partners_img/kirovogradgaz.png'
import naftogaz from '../assets/partners_img/naftogaz.png'

export default {
    name: 'Partners',
    data: () => ({
        cards: [
            {
                title: "Дiя",
                order1: 'order2',
                order2: 'order1',
                imgs: [
                    {
                        img: diya,
                    }
                ],
                texts: [
                      {
                          text: "Співпраця з інтегрованою системою електронної ідентифікації ID.GOV.UA державного підприємства “ДІЯ” дає можливість з легкістю ідентифікувати користувачів наших мобільних додатків і розширити перелік послуг, які ми можемо запропонувати через наші системи. Електронний цифровий підпис дає можливість підписувати документи та оформляти офіційні листи. Одним з явних прикладів користі інтеграції з сервісом «ДІЯ» є можливість оформляти офіційні звернення на гарячу лінію міського голови або губернатора регіону."
                    },
                ]
            },
            {
                title: "Водоканали",
                order1: 'order1',
                order2: 'order2',
                imgs: [
                    {
                        img: kharkiv,
                        img_2: khmelnitsk,
                    },
                    
                    {
                        img: lviv,
                        img_2: noname,
                    },
                    {
                        img: rivne,
                        img_2: ternopil,
                    },
                ],
                texts: [
                    {
                        text: "Труднощі з передачею показань лічильників, інформуванням про планові й аварійні відключення води, а також зі зворотнім зв'язком підприємств до споживачів мотивувало нас створити унікальні мобільні додатки для виконання всіх цих завдань."
                    },
                    {
                        text: "Основними нашими партнерами є:"
                    },
                    {
                        text: "- КП  «Харківводоканал»"
                    },
                    {
                        text: "- МКП  «Хмельницькводоканал»"
                    },
                     {
                        text: "- КП  «Міськводоканал» м.Суми"
                    },
                     {
                        text: "- КП  «ВУВКГ Водоканал» м.Шостка"
                    },
                     {
                        text: "- КП  «Рівнеоблводоканал»"
                    },
                     {
                        text: "- КП  «Тернопільводоканал»"
                    },
                     {
                        text: "- КП  «Львівводоканал»"
                    },
                ]
            },
            {
                title: "Енергопостачальні компанії",
                order1: 'order2',
                order2: 'order1',
                imgs: [
                   {
                        img: zhytomyr,
                        img_2: teploenergetic,
                    },
                    {
                        img: kirovograd,
                        img_2: koek,
                    },
                    {
                        img: kharkivoblenergo,
                        img_2: kharkivenergozbut,
                    },
                ],
                 texts: [
                    {
                        text: "Перший проєкт компанії IT Serve був пов'язаний саме з енергетичною сферою. Мобільний додаток «Харківенерго» став пілотним проєктом і відразу ж показав величезний потенціал розвитку в цій галузі."
                    },
                    {
                        text: "Основними нашими партнерами в енергетичній сфері є:"
                    },
                    {
                        text: "- ПрАТ  «Харківенергозбут»"
                    },
                    {
                        text: "- АТ  «ХарківОблЕнерго»"
                    },
                     {
                        text: "- «Кіровоградська обласна енергопостачальна компанія»"
                    },
                     {
                        text: "- КП  «Теплоенергетик» м. Кропивницький"
                    },
                     {
                        text: "- ПрАТ  «Кіровоградобленерго»"
                    },
                     {
                        text: "- ТОВ  «Житомирська обласна енергопостачальна компанія»"
                    },
                     {
                        text: "- АТ  «Житомиробленерго»"
                    },
                ]
            },
            {
                title: "Газові Компанії",
                order1: 'order1',
                order2: 'order2',
                imgs: [
                     {
                        img: kirovogradgaz,
                    },
                    {
                        img: naftogaz,
                    },
                ],
                texts: [
                    {
                        text: "Співпраця з газовими підприємствами є пріоритетним для нас завданням. Ми вивчаємо всі проблеми, які стосуються послуг постачання газу для населення та юридичних осіб, оплат за дані послуги, а також супутні питання. В ході переговорів з різними газовими підприємствами, знаходимо компромісні рішення щодо створення необхідних платформ для надання всіх послуг в цій сфері.Основними нашими партнерами в цій сфері на сьогодні є:"
                    },
                    {
                        text: "- ВАТ  «Кіровоградгаз»"
                    },
                     {
                        text: "- ТОВ «Газопостачальна компанія «Нафтогаз України»"
                    },

                ]
            },
        ]
    })
}
</script>

<style scoped>
.center {
    align-items: center;
    text-align: center;
}

.img_size {
    height: 165px;
    width: 165px;
}

.order1 {
    order: 0;
}

.order2 {
    order: 1;
}

</style>