<template>
    <div class="contacts mt-15 mb-16 mx-3">
         <v-container>
             <v-row align="end">
                <v-col cols="12" class="text-center">
                    <h2 
                    :class="{'title_h2_for_desktop': $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl}" 
                    class="title_h2_for_mobile"
                    >
                        Ми <span>завжди</span> поруч
                    </h2>
                </v-col>
        </v-row>
        <v-row align="center">
            <v-col class="text-center mx-auto mt-2" cols="8" md="3">
                <hr class="title_hr ">
            </v-col>
        </v-row>

        <v-row class="d-flex flex-lg-wrap-reverse justify-space-around">
            <v-col cols="12" md="5" class="order-md-1">
                <v-row align="center" class="mt-15 mt-md-15 align-self-center justify-space-around">
                    <v-col cols="12">
                        <img class="w_100" src="../assets/contacts_img/map.png" alt="">
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="order-md-0 align-self-center">
                 <v-row
                    v-for="element in elements" 
                    :key="element.index"
                    class="mt-7 mt-md-15 justify-md-space-around"
                    >
                        <v-col cols="12" md="8" class="d-flex">
                            <div class="circile circile_adress mr-7">
                                <v-icon x-large color="white" class="img_center_positioning">
                                {{ element.icon }}
                                </v-icon>
                            </div>
                            <div class="align-self-center">
                                <p>
                                    {{ element.title }}
                                </p>
                            </div>
                        </v-col>
                    </v-row>
            </v-col>
        </v-row>

        <UserFormAPI/>
         </v-container>

        
    </div>
</template>

<script>
import UserFormAPI from './UserFormAPI.vue'

export default {
    name: 'Contacts',
    components: {
        UserFormAPI,
    }, 
    data: () => ({ 
        elements: [
            {
                icon: 'mdi-map-marker',
                title: 'м. Харків'
            },
             {
                icon: 'mdi-email-outline',
                title: 'contact@it-serve.net'
            },
             {
                icon: 'mdi-phone',
                title: '+380630210311'
            }
        ]
    })
}
</script>

<style scoped>
.title_h2 {
    font-size: 50px !important;
}

.circle_map {
    width: auto;
    height: auto; 
}

.img_size {
    width: 100%;
      
}

p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.38;
    color: #1B1B44;
}

.logo_on_map {
    position: relative;
    height: 70px;
    background: #FFFFFF;
    border: 1px solid #807DF4;
    box-sizing: border-box;
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
}

.circile_adress {
    height: 60px;
    width: 60px;
    background: linear-gradient(133.29deg, #42D3CF 9.59%, #807CF4 97.35%);
} 
</style>