<template>
    <div class="support_block">
        <!-- <div class="top_div d-flex align-center justify-start">
            <v-btn icon class="ml-2" @click="back"> 
                <v-icon color="white" large>
                    mdi-chevron-left
                </v-icon>
            </v-btn>
        </div> -->
        
        <iframe class="iframe" src="https://tawk.to/chat/61bc2323c82c976b71c1d9f7/1fn3eih86" frameborder="0"></iframe>
    </div>
</template>

<script>
export default {
    methods: {
       back() {
           this.$router.go(-1)
       }
    },

    mounted() {
        window.location.href = 'https://tawk.to/chat/61bc2323c82c976b71c1d9f7/1fn3eih86'; 
    }

}
</script>

<style scoped>
    .support_block {
        top: 0px;
        color: rgb(0, 0, 0);
        height: 100vh;
        position: fixed;
        z-index: 100;
        background: #fff;
        width: 100%;
        -webkit-overflow-scrolling: touch;
         overflow-y: scroll;
    }
    .iframe {
        position: absolute;
        width: 100%;
        min-width:  100%;
        height: 85vh;
        min-height: 85vh;
        z-index: 1000;
    }

    .top_div {
        width: 100%;
        background: #2e73b4;
        height: 7vh;
    }
</style>